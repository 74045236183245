/* Tabulator v3.5.3 (c) Oliver Folkerd */
.tabulator {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  font-size: 1rem;
  text-align: left;
  width: 100%;
  max-width: 100%;
  -ms-transform: translatez(0);
  transform: translatez(0);
}

.tabulator[tabulator-layout="fitDataFill"] .tabulator-tableHolder .tabulator-table {
  min-width: 100%;
}

.tabulator.tabulator-block-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tabulator .tabulator-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  background-color: #fff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid #dee2e6;
  background: #e6e6e6;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  position: relative;
  padding: 0.75rem;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #999;
  padding: 1px;
  background: #fff;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #dee2e6;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols .tabulator-col:last-child {
  margin-right: -1px;
}

.tabulator .tabulator-header .tabulator-col:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col.ui-sortable-helper {
  position: absolute;
  background-color: #e6e6e6 !important;
  border: 1px solid #dee2e6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 25px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-arrow {
  border-top: 6px solid #666;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-frozen {
  display: inline-block;
  position: absolute;
  z-index: 10;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #dee2e6;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #dee2e6;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  width: 100%;
  background: white !important;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: white !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 200%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder[tabulator-render-mode="virtual"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  position: relative;
  display: inline-block;
  background-color: transparent;
  white-space: nowrap;
  overflow: visible;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.05) !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #dee2e6;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #dee2e6;
}

.tabulator .tabulator-col-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-footer {
  padding: 5px 10px;
  border-top: 2px solid #dee2e6;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  margin: -5px -10px 5px -10px;
  text-align: left;
  background: rgba(13, 13, 13, 0) !important;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: rgba(13, 13, 13, 0) !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
  border-bottom: none;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  margin: 0;
  margin-top: 5px;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-right: none;
  background: rgba(255, 255, 255, 0.2);
  color: #007bff;
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page[data-page="first"] {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabulator .tabulator-footer .tabulator-page[data-page="last"] {
  border: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tabulator .tabulator-footer .tabulator-page.active {
  border-color: #007bff;
  background-color: #007bff;
  color: #fff;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  border-color: #dee2e6;
  background: #fff;
  color: #6c757d;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  border-color: #dee2e6;
  background: #e9ecef;
  color: #0056b3;
}

.tabulator .tablulator-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.tabulator .tablulator-loader .tabulator-loader-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  font-weight: bold;
  font-size: 16px;
}

.tabulator .tablulator-loader .tabulator-loader-msg.tabulator-loading {
  border: 4px solid #333;
  color: #000;
}

.tabulator .tablulator-loader .tabulator-loader-msg.tabulator-error {
  border: 4px solid #D00;
  color: #590000;
}

.tabulator.thead-dark .tabulator-header {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.tabulator.thead-dark .tabulator-header .tabulator-col {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.tabulator.table-dark {
  background-color: #212529;
}

.tabulator.table-dark:not(.thead-light) .tabulator-header {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.tabulator.table-dark:not(.thead-light) .tabulator-header .tabulator-col {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.tabulator.table-dark .tabulator-tableHolder {
  color: #fff;
}

.tabulator.table-dark .tabulator-row {
  border-color: #32383e;
}

.tabulator.table-dark .tabulator-row:hover {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

.tabulator.table-striped .tabulator-row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.tabulator.table-striped .tabulator-row:nth-child(even).tabulator-selected {
  background-color: #9ABCEA;
}

.tabulator.table-striped .tabulator-row:nth-child(even).tabulator-selectable:hover {
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.tabulator.table-striped .tabulator-row:nth-child(even).tabulator-selected:hover {
  background-color: #769BCC;
  cursor: pointer;
}

.tabulator.table-striped.table-dark .tabulator-row:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

.tabulator.table-bordered {
  border: 1px solid #dee2e6;
}

.tabulator.table-bordered .tabulator-header .tabulator-col {
  border-right: 1px solid #dee2e6;
}

.tabulator.table-bordered .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: 1px solid #dee2e6;
}

.tabulator.table-borderless .tabulator-header {
  border: none;
}

.tabulator.table-borderless .tabulator-row {
  border: none;
}

.tabulator.table-sm .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0.3rem !important;
}

.tabulator.table-sm .tabulator-tableHolder .tabulator-table .tabulator-row {
  min-height: 1.6rem;
}

.tabulator.table-sm .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 0.3rem !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-primary {
  background: #b8daff !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-secondary {
  background: #d6d8db !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-success {
  background: #c3e6cb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-info {
  background: #bee5eb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-warning {
  background: #ffeeba !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-danger {
  background: #f5c6cb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-light {
  background: #fdfdfe !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-dark {
  background: #c6c8ca !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.table-active {
  background: rgba(0, 0, 0, 0.075) !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-primary {
  background: #007bff !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-secondary {
  background: #6c757d !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-success {
  background: #28a745 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-info {
  background: #17a2b8 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-warning {
  background: #ffc107 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-danger {
  background: #dc3545 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-light {
  background: #f8f9fa !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-dark {
  background: #343a40 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.bg-active {
  background: rgba(0, 0, 0, 0.075) !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-primary {
  background: #b8daff !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-secondary {
  background: #d6d8db !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-success {
  background: #c3e6cb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-info {
  background: #bee5eb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-warning {
  background: #ffeeba !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-danger {
  background: #f5c6cb !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-light {
  background: #fdfdfe !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-dark {
  background: #c6c8ca !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.table-active {
  background: rgba(0, 0, 0, 0.075) !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-primary {
  background: #007bff !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-secondary {
  background: #6c757d !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-success {
  background: #28a745 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-info {
  background: #17a2b8 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-warning {
  background: #ffc107 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-danger {
  background: #dc3545 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-light {
  background: #f8f9fa !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-dark {
  background: #343a40 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.bg-active {
  background: rgba(0, 0, 0, 0.075) !important;
}

.tabulator-row {
  position: relative;
  box-sizing: border-box;
  min-height: 2.5rem;
  background-color: transparent;
  border-bottom: 1px solid #dee2e6;
}

.tabulator-row.tabulator-selectable:hover {
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.tabulator-row.tabulator-selected {
  background-color: #9ABCEA;
}

.tabulator-row.tabulator-selected:hover {
  background-color: #769BCC;
  cursor: pointer;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  pointer-events: none !important;
  z-index: 15;
}

.tabulator-row .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  display: inline-block;
  position: absolute;
  background-color: inherit;
  z-index: 10;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #dee2e6;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #dee2e6;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 1rem;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1D68CD;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input, .tabulator-row .tabulator-cell.tabulator-editing select {
  border: 1px;
  background: transparent;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #dd0000;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input, .tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: #dd0000;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: transparent;
  font-weight: bold;
  font-size: 1.1em;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: .7;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #999;
  padding: 5px;
  padding-left: 10px;
  background: #fafafa;
  font-weight: bold;
  min-width: 100%;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  border-bottom: 0;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 .tabulator-arrow {
  margin-left: 20px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 .tabulator-arrow {
  margin-left: 40px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 .tabulator-arrow {
  margin-left: 60px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 .tabulator-arrow {
  margin-left: 80px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 .tabulator-arrow {
  margin-left: 100px;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid #666;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: #666;
}
